.connModal .modal-dialog{
    margin-top: 42px;
    margin-right: 0px;
    
}
.connModal .modal-content{
    border-radius: 4px;
    width:53%;
    margin-left: 204px;
    align-items: center;
    top: 20px;
    right: 84px;
}