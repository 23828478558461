.loaderModal .modal-content{
    background: content-box;
    border: none;
}

.loaderModal .modal-content {
    background: content-box;
    border: none;
    text-align: center;
}
.modal-dialog.modal-sm.modal-dialog-centered {
    background: #00000094;
    width: 100%;
    max-width: 100%;
    margin: 0;
    height: 100vh;
}
.loaderModal .modal-content div{
    justify-content:center ;
}